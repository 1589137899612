.site-text-search-input {
	text-overflow: ellipsis;
}

.site-text-search-results-area {
	.col-mw-330 {
		min-width: 330px;
	}

	.col-mw-200 {
		min-width: 200px;
	}
}

.modern-vehicle-card {
	max-height: 110px;
}

.modern-search-dropdown {
	z-index: 999;
}

.modern-search-flyout {
	position: fixed;
	top: 0;
	right: -400px;
	width: 400px;
	max-width: 100%;
	height: 100vh;
	z-index: 99999;
	transition: 0.25s;
}

.modern-search-modal {
	display: block;
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99998;
	background-color: rgba(0, 0, 0, 0.6);
}

.slide-left {
	right: 0;
}
